import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { Container } from "../../helpers/container";
import { useWidth } from "../../hooks/useWidth";
import { Button } from "../utilityComponents/button";
import { MenuTile } from "./menuTile";

export const MenuComponentFragment = ({
  label,
  items,
  accessories,
  myAccessories,
  drink,
}: any) => {
  const { isDesktop } = useWidth();
  const [isCollapse, setIsCollapse] = useState(false);

  return (
    <>
      {" "}
      {items && (
        <Container className="MCF">
          {isDesktop ? (
            <section className="MCF" id={encodeURI(label)}>
              <h1 className="MCF__header">{label}</h1>
              <div className="MCF__list">
                {items &&
                  items.map((itemProperties: any) => {
                    return (
                      <MenuTile
                        key={itemProperties.attributes.title}
                        drink={drink}
                        accessories={accessories}
                        {...itemProperties.attributes}
                      />
                    );
                  })}
              </div>

              {myAccessories && (
                <h1 className="menu__accessories">{myAccessories}</h1>
              )}
              <div className="menu__btnContainer">
                <Button
                  onClick={() =>
                    (window.location.href = "https://koneser.s4honline.pl/")
                  }
                  style={{ margin: "0 auto" }}
                  label="Zamów online"
                  size="medium"
                  type="primary"
                />
              </div>
            </section>
          ) : (
            <div>
              <h1
                className="MCF__header"
                onClick={() => setIsCollapse(!isCollapse)}
                style={{ marginBottom: "1rem" }}
              >
                <div>{label}</div>
                <KeyboardArrowDownIcon
                  sx={{
                    transition: ".2s",
                    transform: `rotate(${!isCollapse ? "0" : "180deg"})`,
                    color: "#123C88",
                    fontSize: 32,
                  }}
                />
              </h1>
              <Collapse isOpen={isCollapse}>
                <div className="MCF__list">
                  {items &&
                    items.map((itemProperties: any) => {
                      return (
                        <MenuTile
                          key={itemProperties.attributes.title}
                          drink={drink}
                          accessories={accessories}
                          {...itemProperties.attributes}
                        />
                      );
                    })}
                  <Button
                    onClick={() =>
                      (window.location.href = "https://koneser.s4honline.pl/")
                    }
                    style={{ margin: "48px 60px", width: "100%" }}
                    label="Zamów online"
                    size="medium"
                    type="primary"
                  />
                </div>
              </Collapse>
            </div>
          )}
        </Container>
      )}{" "}
    </>
  );
};
