import { weeklyOffer } from "./menuTypeOffers";

export const week = (menuTypes) => [
  weeklyOffer(menuTypes)?.find(({ day }: any) => day === "Poniedziałek"),
  weeklyOffer(menuTypes)?.find(({ day }: any) => day === "Wtorek"),
  weeklyOffer(menuTypes)?.find(({ day }: any) => day === "Środa"),
  weeklyOffer(menuTypes)?.find(({ day }: any) => day === "Czwartek"),
  weeklyOffer(menuTypes)?.find(({ day }: any) => day === "Piątek"),
  weeklyOffer(menuTypes)?.find(({ day }: any) => day === "Sobota"),
  weeklyOffer(menuTypes)?.find(({ day }: any) => day === "Niedziela"),
];
