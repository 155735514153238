import { graphql } from "gatsby";
import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { MenuComponent } from "../components/menuPageComponents/menuComponent";
import { renderMenu } from "../components/menuPageComponents/menuComponentRender";
import { SeasonalOffer } from "../components/menuPageComponents/seasonalOffer";
import { Slider } from "../components/menuPageComponents/slider";
import { Button } from "../components/utilityComponents/button";
import { Layout } from "../components/utilityComponents/layout";
import { sortedFood } from "../helpers/menuCategorySorter";
import { seasonalOffer } from "../helpers/menuTypeOffers";
import { week } from "../helpers/week";

const MenuPage = ({ data }) => {
  const [isAlko, setIsAlko] = useState(false);

  const menuCategory = data?.allStrapiMenuCategory?.nodes;
  const menuTypes = data?.allStrapiMenuType?.nodes;

  const menuItemsData = menuCategory.map((menuCategory) => {
    return {
      title: menuCategory?.title,
      order: menuCategory?.order,
      category: menuCategory?.menuType,
      accessories: menuCategory?.accessories,
      menu_items: menuCategory.menu_items?.map((attributes) => {
        return {
          attributes: {
            ...attributes,
          },
        };
      }),
    };
  });

  const foodMenu = menuItemsData.filter(
    ({ category, title }) => category === "Menu" && title
  );

  const drinksMenu = menuItemsData.filter(
    ({ category, title }) => category === "Drinks" && title
  );

  const alkoholMenu = menuItemsData.filter(
    ({ category, title }) => category === "Alkohol" && title
  );

  return (
    <Layout
      darkBackground
      headerChildren={<h1 className="polityka_prywatnosci__header">MENU</h1>}
    >
      <div>
        <Slider items={week(menuTypes)} />
        <SeasonalOffer items={seasonalOffer(menuTypes)} />
        <MenuComponent menuCategories={menuItemsData} />
        {renderMenu(sortedFood(foodMenu), false)}
        {renderMenu(sortedFood(drinksMenu), true)}
        <div style={{ display: "flex", justifyContent: "center" }} id="alko">
          <h1 className="menu__utilityHeader menu__alkoHeader">ALKOHOLE</h1>
        </div>
        <Collapse isOpen={!isAlko}>
          <p className="menu__alkoParagraph">
            dostępne są wyłącznie na miejscu oraz jedynie dla osób pełnoletnich
          </p>
          <div className="menu__btnContainer" style={{ border: "none" }}>
            <Button
              onClick={() => setIsAlko(true)}
              style={{ margin: "0 auto" }}
              label="Rozumiem"
              size="medium"
              type="primary"
            />
          </div>
        </Collapse>
        <Collapse isOpen={isAlko} id="visibleAlko">
          {renderMenu(sortedFood(alkoholMenu), true)}
        </Collapse>
      </div>
    </Layout>
  );
};
export const query = graphql`
  query data {
    allStrapiMenuCategory {
      nodes {
        strapiId
        title
        accessories
        order
        menuType
        menu_items {
          description
          day
          id
          image {
            url
          }
          price
          rightTitle
          spicyProduct
          superSpicyProduct
          title
        }
      }
    }
    allStrapiMenuType {
      nodes {
        id
        menuTypeName
        menu_items {
          description
          day
          image {
            url
          }
          price
          title
          superSpicyProduct
          spicyProduct
          rightTitle
        }
      }
    }
  }
`;

export default MenuPage;
