import React from "react";
import { Container } from "../../helpers/container";
import { Button } from "../utilityComponents/button";

export const MenuComponent = ({ menuCategories }: any) => {
  return (
    <Container className="menuComponent">
      <div className="menuComponent">
        <div className="menuComponent__header">
          <h3 className="menu__utilityHeader" style={{ margin: "43px 0 20px" }}>
            Menu
          </h3>

          <div className="menuComponent__list">
            {menuCategories.map(({ category, title }: any) => {
              return category !== "Alkohol" ? (
                <Button
                  style={{ margin: ".5rem" }}
                  onClick={() => {
                    window.location.href = `#${title}`;
                  }}
                  label={title}
                  size="small"
                  type="secondary"
                  key={title}
                />
              ) : null;
            })}
            <Button
              style={{ margin: ".5rem" }}
              onClick={() => {
                window.location.href = `#alko`;
              }}
              label="Alkohole"
              size="small"
              type="secondary"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};
