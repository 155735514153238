import React from "react";
import { MenuComponentFragment } from "./menuComponentFragment";

export const renderMenu = (menuCategory, isDrink) => {
  return menuCategory.map((singleCategory) => {
    return (
      <MenuComponentFragment
        key={singleCategory.title}
        drink={isDrink}
        label={singleCategory.title}
        items={singleCategory.menu_items?.map((singleMenuItem) => {
          //do usunięcia
          return singleMenuItem;
        })}
        myAccessories={singleCategory.accessories}
      />
    );
  });
};
