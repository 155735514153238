import React from "react";
import { strapiUrl } from "../../helpers/strapiUrl";
import { useWidth } from "../../hooks/useWidth";
import leaf from "./../../assets/images/leaf.svg";
import pepper from "./../../assets/images/pepper.svg";

export const MenuTile = ({
  title,
  description,
  image,
  price,
  vegeProduct,
  spicyProduct,
  superSpicyProduct,
  accessories,
  rightTitle,
  drink,
}: any) => {
  const { isDesktop } = useWidth();
  const to = () => {
    if (!image) {
      return;
    } else {
      return (
        <img
          className="menuTile__img"
          style={{ padding: drink ? ".75rem" : "" }}
          src={strapiUrl(image.url)}
        />
      );
    }
  };
  return (
    <div
      className={`menuTile ${accessories && "accessories"} ${
        drink ? "drink" : ""
      }`}
    >
      {accessories ? (
        <>
          <h3 className="menuTile__header">{title}</h3>
          <label className="menuTile__price">
            <div className="menuTile__blank" />
            {price}
          </label>
        </>
      ) : (
        <>
          {to()}
          <div className="menuTile__content">
            <h3 className="menuTile__header">
              <div>
                {title}{" "}
                <span className="menuTile__header--rightTitle">
                  {rightTitle}
                </span>
              </div>
              <div className="menuTile__icons">
                {vegeProduct && (
                  <img height={isDesktop ? "20px" : "14"} src={leaf} />
                )}
                {vegeProduct && spicyProduct && " / "}
                {vegeProduct && superSpicyProduct && " / "}
                {spicyProduct && (
                  <img height={isDesktop ? "20px" : "14"} src={pepper} />
                )}
                {superSpicyProduct && (
                  <>
                    <img height={isDesktop ? "20px" : "14"} src={pepper} />{" "}
                    <img height={isDesktop ? "20px" : "14"} src={pepper} />
                  </>
                )}
              </div>
            </h3>
            <p className="menuTile__paragraph">{description || ""}</p>
            <label className="menuTile__price">
              <div className="menuTile__blank" />
              {price}
            </label>
          </div>
        </>
      )}
    </div>
  );
};
