export const weeklyOffer = (menuTypes) =>
  menuTypes
    .find(({ menuTypeName }: any) => menuTypeName === "weekly_offer")
    ?.menu_items.map((singleMenuTypeDataWeekly) => singleMenuTypeDataWeekly);

export const seasonalOffer = (menuTypes) =>
  menuTypes
    .find(({ menuTypeName }: any) => menuTypeName === "season_offer")
    ?.menu_items.map(
      (singleMenuTypeDataSeasonal) => singleMenuTypeDataSeasonal
    );
