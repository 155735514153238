import React from "react";
import { Carousel } from "react-responsive-carousel";
import leftArrow from "../../assets/images/leftArrow.svg";
import rightArrow from "../../assets/images/rightArrow.svg";
import { Container } from "../../helpers/container";
import { strapiUrl } from "../../helpers/strapiUrl";
import { Button } from "../utilityComponents/button";

const renderNext = (clickHandler: any) => (
  <button
    onClick={clickHandler}
    type="button"
    aria-label="next slide / item"
    className="control-arrow control-next"
  >
    <img src={rightArrow} />
  </button>
);

const renderPrevious = (clickHandler: any) => (
  <button
    onClick={clickHandler}
    type="button"
    aria-label="next slide / item"
    className="control-arrow control-prev"
  >
    <img src={leftArrow} />
  </button>
);

const week = [
  "Poniedziałek",
  "Wtorek",
  "Środa",
  "Czwartek",
  "Piątek",
  "Sobota",
  "Niedziela",
];

type ItemProps = {
  title: string;
  day: string;
  description: string;
  price: string;
  image: {
    url: string;
  };
};

const Item = ({ title, day, description, price, image }: ItemProps) => {
  return (
    <div>
      <p className="slider__paragraph">{day}</p>
      <div className="sliderItem">
        <img
          className="sliderItem__img"
          src={image ? strapiUrl(image.url) : ""}
        />
        <div className="sliderItem__content">
          <h3 className="sliderItem__header">{title}</h3>
          <p className="sliderItem__paragraph">{description}</p>
          <h3 className="sliderItem__price">{price}</h3>
        </div>
      </div>
    </div>
  );
};

export const Slider = ({ items }: any) => {
  const carouselProperties = {
    showStatus: false,
    showThumbs: false,
    className: "slider__carousel",
    emulateTouch: true,
    renderArrowNext: renderNext,
    renderArrowPrev: renderPrevious,
    infiniteLoop: true,
  };

  return (
    <Container className="slider">
      <div className="slider">
        <h1 className="menu__utilityHeader slider__header">Oferta tygodnia </h1>

        <Carousel {...carouselProperties}>
          {items.map((itemProperties: any) => {
            return <Item key={{ itemProperties }} {...itemProperties} />;
          })}
        </Carousel>
        <div className="menu__btnContainer">
          <Button
            onClick={() =>
              (window.location.href = "https://koneser.s4honline.pl/")
            }
            style={{ margin: "0 auto" }}
            label="Zamów online"
            size="medium"
            type="primary"
          />
        </div>
      </div>
    </Container>
  );
};
