import React from "react";
import { Container } from "../../helpers/container";
import { Button } from "../utilityComponents/button";
import { MenuTile } from "./menuTile";

export const SeasonalOffer = ({ items }: any) => {
  return (
    <Container className="seasonalOffer">
      <div className="seasonalOffer">
        <h1 className="menu__utilityHeader seasonalOffer__header">
          Oferta sezonowa
        </h1>
        <div className="seasonalOffer__tiles">
          {items?.map((itemProperties: any) => (
            <MenuTile key={itemProperties.title} {...itemProperties} />
          ))}
        </div>
        <div className="menu__btnContainer seasonalOffer__btnContainer">
          <Button
            onClick={() =>
              (window.location.href = "https://koneser.s4honline.pl/")
            }
            style={{ margin: "0 auto" }}
            label="Zamów online"
            size="medium"
            type="primary"
          />
        </div>
      </div>
    </Container>
  );
};
